<template>
  <div class="callback">
    Wait...
  </div>
</template>

<script>
export default {
  name: 'Callback',
  components: {
  },
  created() {
    const params = this.getHashParams();
    const accessToken = params.access_token;
    if (accessToken) {
      this.$store.dispatch('setAccess', accessToken);
    }
    this.$router.push({ path: '/' });
  },
  methods: {
    getHashParams() {
      const hash = window.location.hash.substr(1);
      const result = hash.split('&').reduce(function (res, item) {
          var parts = item.split('=');
          res[parts[0]] = parts[1];
          return res;
      }, {});

      return result;
    }
  },
}
</script>
