import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueTouch from 'vue-touch';

Vue.use(Vuetify);
Vue.use(VueTouch, {name: 'v-touch-event'});

export default new Vuetify({
    theme: {
        dark: true,
        themes: {
            light: {
                primary: '#1ED760',
                secondary: '#18b550',
            },
            dark: {
                primary: '#1ED760',
                secondary: '#18b550',
            },
        },
    },
});
