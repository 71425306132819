<template>
  <div>
    <v-btn @click="authorize" :x-large="large" :class="color">
      Login
      <v-icon right>mdi-spotify</v-icon>
    </v-btn>
  </div>
</template>

<script>
  export default {
    name: 'Auth',
    props: {
      color: String,
      large: Boolean,
    },
    data: () => ({
    }),
    methods: {
      authorize(){
        window.open(
          'https://accounts.spotify.com/authorize?client_id=325ee4bb51ce46529b323af2b4c58a17&response_type=token&scope=user-modify-playback-state,user-read-playback-state,playlist-read-private,playlist-modify-public,playlist-modify-private&redirect_uri=https://spotinder.steffendev.de/callback/',
          '_self',
        );
        return false;
      }
    },
  }
</script>
