import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';

const vuexPersist = new VuexPersist({
  key: 'spotify',
  storage: window.localStorage,
});

Vue.use(Vuex);

export default new Vuex.Store({
  plugins: [vuexPersist.plugin],
  state: {
    accessToken: null,
    user: null,
    heardSongs: [],
    validUntil: null,
  },
  mutations: {
    setAccess(state, value) {
      state.accessToken = value;
      state.validUntil = Math.round(((new Date()).getTime() + (1*60*60*1000)) / 1000);
    },
    setUser(state, value) {
      state.user = value;
    },
    addHeardSong(state, value) {
      state.heardSongs.push(value);
    },
  },
  actions: {
    setAccess(context, value) {
      context.commit('setAccess', value);
    },
    setUser(context, value) {
      context.commit('setUser', value);
    },
    addHeardSong(context, value) {
      context.commit('addHeardSong', value);
    },
  },
  modules: {
  }
})
