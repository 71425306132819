<template>
    <div class="swiper fill-height d-flex align-center">
        <v-row v-if="!isValid" no-gutters>
            <v-col cols="12" class="d-flex justify-center">
                <v-card max-width="80%" width="80%">
                    <v-card-title>Setup</v-card-title>
                    <v-card-text>
                        <v-form
                            @submit.prevent="validateForm"
                            ref="form"
                            v-model="formValid"
                        >
                            <v-row>
                                <v-col cols="6">
                                    <v-select
                                        item-text="name"
                                        item-value="value"
                                        v-model="selectedDevice"
                                        :items="selectableDevices"
                                        label="Select Device"
                                        outlined
                                        :rules="[rules.required, rules.length(0)]"
                                    />
                                </v-col>
                                <v-col cols="6">
                                    <v-select
                                        item-text="name"
                                        item-value="value"
                                        v-model="selectedPlaylist"
                                        :items="selectablePlaylists"
                                        label="Select Playlist"
                                        outlined
                                        :rules="[rules.required, rules.length(0)]"
                                    />
                                </v-col>
                                <v-col cols="12">
                                    <v-autocomplete
                                        v-model="selectedGenres"
                                        :items="recommendGenres"
                                        label="Select Genres"
                                        multiple
                                        attach
                                        chips
                                        outlined
                                        clearable
                                        deletable-chips
                                        :rules="[rules.required, rules.length(0), rules.maxLength(5)]"
                                    />
                                </v-col>
                                <v-col cols="12" class="d-flex justify-center align-center">
                                    <v-btn @click="validateForm()" x-large class="primary">
                                        Start
                                        <v-icon right>mdi-music</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <v-row v-if="currentSong && isValid && (itemCount < recommendSongs.length)" no-gutters>
            <v-col cols="12" class="d-flex justify-center align-center">
                <v-touch-event
                    v-on:swipeleft="addToHeardSong(currentSong.uri)"
                    v-on:swiperight="addSongToPlaylist(currentSong.uri)"
                    class="swiper__card-wrapper"
                >
                    <v-card
                        class="mx-5"
                        max-width="100%"
                        style="cursor:pointer;"
                    >
                        <div>
                            <v-img
                                v-if="currentSong.images !== undefined"
                                :src="(currentSong.images.length > 0) ? currentSong.images[0].url : ''"
                                cover
                                aspect-ratio="1"
                            />
                        </div>

                        <v-card-title>
                            {{ currentSong.name }}
                        </v-card-title>
                        <v-card-subtitle>
                            <div v-for="(artist, j) in currentSong.artists" :key="j">
                                <a :href="artist.external_urls.spotify" target="_blank">{{ artist.name }}</a>
                            </div>
                        </v-card-subtitle>
                    </v-card>
                    <div class="d-flex justify-space-around align-start mt-4">
                        <v-btn @click="oneSongBack()" fab disabled>
                            <v-icon color="blue">mdi-replay</v-icon>
                        </v-btn>
                        <v-btn @click="addToHeardSong(currentSong.uri)" x-large fab>
                            <v-icon color="red">mdi-close</v-icon>
                        </v-btn>
                        <v-btn @click="addSongToPlaylist(currentSong.uri)" x-large fab>
                            <v-icon color="primary">mdi-heart</v-icon>
                        </v-btn>
                        <v-btn @click="playSongAtZero(currentSong.uri)" fab>
                            <v-icon color="blue">mdi-rewind</v-icon>
                        </v-btn>
                    </div>
                </v-touch-event>
            </v-col>
        </v-row>
        <v-row v-if="isValid && (itemCount >= recommendSongs.length)" no-gutters>
            <v-col cols="12" class="d-flex justify-center align-center mb-3">
                <v-btn x-large @click="getRecommendSongs()">
                    Load more
                    <v-icon class="ml-2" color="primary">mdi-arrow-right-drop-circle</v-icon>
                </v-btn>
            </v-col>
            <v-col cols="12" class="d-flex justify-center align-center">
                <v-btn href="/">
                    New Search
                    <v-icon class="ml-2" color="red">mdi-reload</v-icon>
                </v-btn>
            </v-col>
        </v-row>
    </div>
</template>

<script>

  export default {
    name: 'Swiper',
    data: () => {
        return {
            rules: {
                required: (v) => {
                    return !!v || 'This field is required';
                },
                length: (length) => {
                    return (v) => {
                        return (v && v.length > length) || `minimum number is ${length + 1}`;
                    };
                },
                maxLength: (length) => {
                    return (v) => {
                        return (v && v.length <= length) || `Maximum number is ${length}`;
                    };
                },
            },
            isFormValid: false,
            formValid: false,
            recommendGenres: [],
            selectedGenres: [],
            recommendSongs: [],
            selectedDevice: null,
            devices: [],
            itemCount: -1,
            currentSong: null,
            playlists: [],
            selectedPlaylist: null,
        };
    },
    async mounted() {
        const devices = await this.getDevices();
        this.devices = devices.devices;

        const playlists = await this.getPlaylists();
        this.playlists = playlists.items;

        const genres = await this.getRecommendGenres();
        this.recommendGenres = genres.genres;
    },
    computed: {
        accessToken() {
            return this.$store.state.accessToken;
        },
        user() {
            return this.$store.state.user;
        },
        isValid() {
            return this.isFormValid;
        },
        prepareRecommendSongs() {
            if (this.recommendSongs.length <= 0) {
                return [];
            }

            const songs = [];
            for (const item of this.recommendSongs) {
                songs.push({ name: item.name, preview: item.preview_url, uri: item.uri, duration: item.duration_ms, images: item.album.images });
            }

            return songs;
        },
        selectableDevices() {
            if (this.devices.length <= 0) {
                return [];
            }

            const devices = [];
            for (const item of this.devices) {
                devices.push({name: item.name, value: item.id });
            }

            return devices;
        },
        selectablePlaylists() {
            if (this.playlists.length <= 0) {
                return [];
            }

            const devices = [];
            for (const item of this.playlists) {
                devices.push({name: item.name, value: item.id });
            }

            return devices;
        }
    },
    methods: {
        async getPlaylists() {
            const response = await fetch(
                `https://api.spotify.com/v1/users/${this.user.id}/playlists?limit=50`, {
                    headers: {
                        'Authorization': 'Bearer ' + this.accessToken
                    },
                }
            );
            return await response.json();
        },
        async getRecommendGenres() {
            const response = await fetch(
                'https://api.spotify.com/v1/recommendations/available-genre-seeds', {
                    headers: {
                        'Authorization': 'Bearer ' + this.accessToken
                    },
                }
            );
            return await response.json();
        },
        async getRecommendSongs() {
            this.recommendSongs = [];
            this.itemCount = -1;
            const response = await fetch(
                `https://api.spotify.com/v1/recommendations?seed_genres=${this.selectedGenres.join()}&limit=100&market=from_token`, {
                    headers: {
                        'Authorization': 'Bearer ' + this.accessToken
                    },
                }
            );

            const songs = await response.json();
            this.recommendSongs = songs.tracks;
            this.getNewCurrentSong();
        },
        async getDevices() {
            const response = await fetch(
                `https://api.spotify.com/v1/me/player/devices`, {
                    headers: {
                        'Authorization': 'Bearer ' + this.accessToken
                    },
                }
            );

            return await response.json();
        },
        async playSong(songUri, duration) {
            await fetch(
                `https://api.spotify.com/v1/me/player/play?device_id=${this.selectedDevice}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': 'Bearer ' + this.accessToken
                    },
                    body: JSON.stringify({
                        "uris": [ songUri ],
                        "position_ms": duration / 2
                    })
                }
            );
        },
        async playSongAtZero(songUri) {
            await fetch(
                `https://api.spotify.com/v1/me/player/play?device_id=${this.selectedDevice}`, {
                    method: 'PUT',
                    headers: {
                        'Authorization': 'Bearer ' + this.accessToken
                    },
                    body: JSON.stringify({
                        "uris": [ songUri ],
                    })
                }
            );
        },
        async addSongToPlaylist(songUri) {
            await fetch(
                `https://api.spotify.com/v1/playlists/${this.selectedPlaylist}/tracks`, {
                    method: 'POST',
                    headers: {
                        'Authorization': 'Bearer ' + this.accessToken
                    },
                    body: JSON.stringify({
                        "uris": [ songUri ]
                    })
                }
            );
            this.addToHeardSong(songUri);
        },
        addToHeardSong(songUri) {
            this.$store.dispatch('addHeardSong', songUri);
            this.getNewCurrentSong();
        },
        getNewCurrentSong() {
            if (this.recommendSongs.length <= 0) {
                return null;
            }

            const length = this.recommendSongs.length;
            if (this.itemCount < length) {
                this.itemCount++;
                const item = this.recommendSongs[this.itemCount];
                const preparedCurrentSong = {
                    id: item.id,
                    name: item.name,
                    preview: item.preview_url,
                    uri: item.uri,
                    duration: item.duration_ms,
                    images: item.album.images,
                    artists: item.artists
                };
                this.currentSong = preparedCurrentSong;
                this.playSong(this.currentSong.uri, this.currentSong.duration)
            } else {
                return null;
            }
        },
        oneSongBack() {
            const itemCountTest = this.itemCount - 1;
            if (itemCountTest >= 0) {
                this.itemCount -= 2;
                this.getNewCurrentSong();
            }
        },
        validateForm() {
            this.isFormValid = this.$refs.form.validate();

            if (this.isFormValid) {
                this.getRecommendSongs();
            }
        },
    },
  }
</script>

<style lang="scss">
    @import '~vuetify/src/styles/settings/_variables';

    @mixin breakpoint($media){
        @media #{map-get($display-breakpoints, $media)} {
            @content;
        }
    }

    .swiper {
        &__card-wrapper {
            width: 100%;
            @include breakpoint('sm-and-up') {
                width: 50%;
            }
            @include breakpoint('md-and-up') {
                width: 30%;
            }
        }
    }
</style>