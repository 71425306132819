<template>
  <div class="home fill-height">
    <div v-if="!accessToken" class="fill-height">
      <v-row no-gutters class="py-5">
        <v-col cols="12" md="6" class="d-flex justify-center align-center">
          <v-img
            src="../assets/screen.png"
            aspect-ratio="1.5"
            contain
          />
        </v-col>
        <v-col cols="12" md="6" class="d-flex justify-center align-center">
          <div style="width:80%" class="text-center">
            <h1 class="mb-2">Spotinder</h1>
            <div class="headline">
              An app in which you can discover new songs based on music genres and add them to your playlist.
              For this purpose, random songs are played automatically and you can either remove them (swipe left) or add them to your playlist (swipe right)
            </div>
            <Auth class="mt-5" color="primary" :large="true"/>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters class="secondary py-5">
        <v-col cols="12" class="text-center mb-3">
          <h1>How does it work?</h1>
        </v-col>
        <v-col cols="12" class="d-flex justify-center align-center">
          <v-list-item-group>
            <v-list subheader flat tile>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large>mdi-numeric-1-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Start Spotify</v-list-item-title>
                  <v-list-item-subtitle>First spotify has to be started on any device so that it can be selected later</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list subheader flat tile>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large>mdi-numeric-2-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Select your device</v-list-item-title>
                  <v-list-item-subtitle>The device on which spotify is running must now be selected</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list subheader flat tile>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large>mdi-numeric-3-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Select a playlist</v-list-item-title>
                  <v-list-item-subtitle>Select a playlist in which your new songs should be saved</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list subheader flat tile>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large>mdi-numeric-4-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Choose genres</v-list-item-title>
                  <v-list-item-subtitle>Select genres in which you would like to find new music</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
            <v-list subheader flat tile>
              <v-list-item>
                <v-list-item-icon>
                  <v-icon x-large>mdi-numeric-5-circle-outline</v-icon>
                </v-list-item-icon>
                <v-list-item-content>
                  <v-list-item-title>Happy Swiping</v-list-item-title>
                  <v-list-item-subtitle>Now a journey of discovery can begin!</v-list-item-subtitle>
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </v-list-item-group>
        </v-col>
      </v-row>
    </div>
    <div v-else class="fill-height">
      <Swiper />
    </div>
  </div>
</template>

<script>
import Auth from '@/components/Auth.vue'
import Swiper from '@/components/Swiper.vue'

export default {
  name: 'Home',
  components: {
    Auth,
    Swiper
  },
  computed: {
    accessToken() {
      return this.$store.state.accessToken;
    }
  }
}
</script>
