<template>
  <v-app>
    <v-app-bar
      app
      color="secondary"
      dark
    >
      <div class="d-flex align-center">
        <a href="/" class="text-decoration-none">
        <div class="d-flex justify-center align-center">
          <v-icon left x-large>mdi-music-box</v-icon>
          <h1 class="white--text d-none d-md-block">Spotinder</h1>
        </div>
        </a>
      </div>

      <v-spacer></v-spacer>

      <div class="headline" v-if="this.accessToken && this.data">
        {{ data.display_name }}
        <v-avatar
          size="48"
          color="primary"
          class="ml-1"
        >
          <img
            :src="(data.images.length > 0) ? data.images[0].url : ''"
          />
        </v-avatar>
        <v-btn icon @click="logout" class="ml-2">
          <v-icon>mdi-location-exit</v-icon>
        </v-btn>
      </div>
      <Auth v-else />
    </v-app-bar>

    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import Auth from '@/components/Auth.vue'

export default {
  name: 'App',
  components: {
    Auth
  },
  data: () => {
    return {
      data: null,
      intervall: null,
    };
  },
  async mounted() {
    if (this.accessToken) {
      this.data = await this.getData();
      if (this.data) {
        this.$store.dispatch('setUser', this.data);
      }

      this.intervall = setInterval(() => {
          const currentTimestamp = Math.round((new Date()).getTime() / 1000);
          const validUntilTimestamp = this.$store.state.validUntil;

          const timeLeft = validUntilTimestamp - currentTimestamp;
          if (timeLeft <= 100) {
            this.logout();
          }
      }, 120000);
    }
  },
  computed: {
    accessToken() {
        return this.$store.state.accessToken;
    }
  },
  methods: {
    async getData() {
        const response = await fetch(
            'https://api.spotify.com/v1/me', {
                headers: {
                    'Authorization': 'Bearer ' + this.accessToken
                },
            }
        );

        const responseJSON = await response.json();
        return responseJSON;
    },
    logout() {
      window.localStorage.clear();
      location.reload();
    }
  },
};
</script>
